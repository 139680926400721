body{
    line-height: 1.5;
    
  }
  *{
    margin:0;
    padding:0;
    
  }
  .container{
    max-width: 1270px;
    display: flex;
    flex-direction: row;
    justify-content: center;

   
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: center;
    justify-self: center;
    flex-direction: row;
  }
  ul{
    list-style: none;
  }
  .footer{
    background-color: #24262b;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      text-decoration: none;



  }

  .footerHeading{
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
    right: 0;
    text-align: end;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .footerHeading::before{
    content: '';
    position: absolute;
    left:0;
    bottom: -10px;
    background-color: RGB(148, 0, 211);
    height: 2px;
    box-sizing: border-box;
    width: 100%;
  }


  .footer-col{
     width: 25%;
     padding: 0 10px;
     text-decoration: none;
  }
  .footer-col h4{
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
  }
  .footer-col h4::before{
    content: '';
    position: absolute;
    left:0;
    bottom: -10px;
    background-color: RGB(148, 0, 211);
    height: 2px;
    box-sizing: border-box;
    width: 50px;
  }
  .footer-col ul li:not(:last-child){
    margin-bottom: 10px;
  }
  .footer-col ul li{
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
    text-decoration: none;
  }
  .footer-col ul li:hover{
    color: #ffffff;
    padding-left: 8px;

  }

  .footer-col a{
    text-decoration: none;
    color: #bbbbbb;

  }

  
  .footer-col a:hover{
    text-decoration: none;
    color: whitesmoke;

  }
  .footer-col .social-links{
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255,255,255,0.2);
    margin:0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
  }
  .footer-col .social-links:hover{
    color: #24262b;
    background-color: #ffffff;
  }
  
  /*responsive*/
  @media(max-width: 767px){
    .footer-col{
      width: 50%;
      margin-bottom: 30px;
  }
  }
  @media(max-width: 574px){
    .footer-col{
      width: 100%;
  }
  }